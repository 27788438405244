import React, { Component } from "react";

class Bio extends Component {
  render() {
    return (
      <div className="container bio">
        <div className="row">
          <div className="col-12">
            <div className="card p-4">
              <h1>Bio</h1>
              <p>
                <strong>Summary</strong>
              </p>
              <p>
                Concept to completion in programming, graphic design, branding,
                photography, motion graphics and film.
              </p>
              <p>
                <strong>Clients</strong>
              </p>
              <p>
                Acura, Atikameksheng Anishnawbek, Averatec, Bacchus Development,
                Bal Yoga, BioVision, Boost Mobile, Damon Elliot, Deamco,
                Experian, Habitat for Humanity, Honda Motors, Hyundai, Hologic,
                Incuity, Izusu Trucks, Jimmy Bennett, John Picard, Jungletrain,
                Kia, Lexus, Matthew McConaughey, Mazda, Mitsubishi Motors,
                Mercedes-Benz, Me and My Big Ideas, Motorcycle Safety
                Foundation, PG&amp;E, Perkin Elmer, Pioneer, Sage Software, Sun
                America, Subaru, Tasting411, Toyota, Prestige Preschool Academy,
                Video Resources, Inc..
              </p>
              <p>
                <strong>History</strong>
              </p>
              <p>
                With experience in the multimedia industry that dates back to
                the late 90s, Rivas has served countless clients ranging
                anywhere from small business to large corporations whether it
                being for a small private audience or for the global masses.
                Extensive experience in UI, UX, web, CD, DVD, kiosk, mobile,
                graphic design, film, 3D, photography, motion graphics, and
                prominent web languages.
              </p>
              <p>
                Rivas earned his degree in Interactive Media Design at the Art
                Institute of Orange County in Santa Ana. Starting his education
                at an early age in the 90s going to computer expos and learning
                DOS with the guidance of his uncles. At age 9 he built his very
                first Intel IBM clone computer and was able to achieve 80wpm
                with Mavis Beacon. At 15 he produced his first skateboarding
                films and the next year he launched his first website on
                GeoCities. Attending special programs during high school he
                modeled, textured, and animated his first 3D environments.
                During this time he also picked up Macromedia Flash 5 and
                learned animation and programming. Today Rivas uses all those
                skills he learned 20 years ago in new emerging technologies such
                as webXR.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Bio;
